import { IconName } from "@hegias/ui-components";
import { PagedResponse } from "common/types/PagedResponse";
import PageQuery from "common/types/PageQuery";

export const ObjectTagCategory = [
	"living",
	"dining",
	"kitchen",
	"working",
	"corridor",
	"sleeping",
	"bathroom",
	"children",
	"outdoor",
	"textiles",
	"plants",
	"lamps",
	"accessories",
	"technical",
	"prototyping",
	"other",
] as const;

export const ObjectSubcategory = [
	"bathtubs",
	"showerTrays",
	"showerAccessories",
	"partitionWalls",
	"washbasins",
	"bidets",
	"bathroomFurniture",
	"washbasinAccessories",
	"wcFacilities",
	"cisterns",
	"wcSeats",
	"urinalSystems",
	"wcAccessories",
	"washbasin",
	"washingAppliances",
	"washroomAccessories",
	"trimmings",
	"radiators",
	"accessoriesForTrimmings",
	"mirrorsAndMirrorCabinets",
	"mirrorAccessories",
	"fittings",
	"accessoriesForFittings",
	"accessories",
] as const;

export const ObjectTagCategoryMap: {
	value: typeof ObjectTagCategory[number];
	icon: IconName;
	translatedValue?: string;
}[] = [
	{ value: "living", icon: "LivingIcon" },
	{ value: "dining", icon: "DiningIcon" },
	{ value: "kitchen", icon: "KitchenIcon" },
	{ value: "working", icon: "WorkingIcon" },
	{ value: "corridor", icon: "CorridorIcon" },
	{ value: "sleeping", icon: "SleepingIcon" },
	{ value: "bathroom", icon: "BathroomIcon" },
	{ value: "children", icon: "ChildrenIcon" },
	{ value: "outdoor", icon: "OutdoorIcon" },
	{ value: "textiles", icon: "TextilesIcon" },
	{ value: "plants", icon: "PlantsIcon" },
	{ value: "lamps", icon: "LampIcon" },
	{ value: "accessories", icon: "AccessoiresIcon" },
	{ value: "technical", icon: "TechnicalIcon" },
	{ value: "prototyping", icon: "PrototypeIcon" },
	{ value: "other", icon: "EllipsisIcon" },
];

export const MaterialTagCategory = [
	"wood",
	"stone",
	"tiles",
	"wallpaper",
	"carpet",
	"color",
	"facade",
	"glass",
	"roof",
	"environment",
] as const;

export const MaterialTagCategoryMap: {
	value: typeof MaterialTagCategory[number];
	icon: IconName;
	translatedValue?: string;
}[] = [
	{ value: "wood", icon: "WoodIcon" },
	{ value: "stone", icon: "StoneIcon" },
	{ value: "tiles", icon: "TilesIcon" },
	{ value: "wallpaper", icon: "WallpaperIcon" },
	{ value: "carpet", icon: "CarpetIcon" },
	{ value: "color", icon: "ColorfulIcon" },
	{ value: "facade", icon: "FacadeIcon" },
	{ value: "glass", icon: "GlassIcon" },
	{ value: "roof", icon: "RoofIcon" },
	{ value: "environment", icon: "EnvironmentIcon" },
];

export const LightTagCategory = [
	"point",
	"spot",
] as const;

export const LightTagCategoryMap: {
	value: typeof LightTagCategory[number];
	icon: IconName;
	translatedValue?: string;
}[] = [
	{ value: "point", icon: "RoofIcon" },	// TODO change icon
	{ value: "spot", icon: "EnvironmentIcon" },	// TODO change icon
];

export const ObjectCategory = ["standing", "hanging", "attached"];

export const ObjectClass = ["0", "1", "2", "3", "4"];

export type Token = {
	creation: string;
	extended: string;
	isExpired: boolean;
	nViews: number;
	name: string;
	value: string;
};

export interface SceneObject {
	_id: string;
	id: string;
	isPublic: boolean;
	meta: {
		detailLinkEN: string | undefined;
		detailLinkDE: string | undefined;
		detailLinkFR: string | undefined;
		detailLinkIT: string | undefined;
		catalogHeaderTextDE: string;
		articleImageUrl: string;
		catalogHeaderTextEN: string;
		sgvsbNr: string | undefined;
		articleNr12Digits: string | undefined;
		category: typeof ObjectTagCategory[];
		description: string;
		isBackup: boolean;
		nEdits: number;
		nViews: number;
		name: string;
		teaser: string;
		thumbnail: string;
		tsCreated: string;
		tsModified: string;
		tags: string[];
		supplierNr?: string;
	};
	object: {
		paintable?: boolean;
		category: string;
		class: string;
		locked: boolean;
		walkable: boolean;
		hasVisibleCollision: boolean;
		lod?: [
			{
				level: number;
				src: string;
			},
		];
		length: number;
		width: number;
		height: number;
		depth: number;
		diameter: number;
	};
	shortid: string;
	tokens: Token[];
	featured?: boolean;
	isVerified?: boolean;
}

export interface Material {
	_id: string;
	id: string;
	isPublic: boolean;
	meta: {
		category: typeof MaterialTagCategory[];
		description: string;
		isBackup: boolean;
		nEdits: number;
		nViews: number;
		name: string;
		teaser: string;
		thumbnail: string;
		tsCreated: string;
		tsModified: string;
		tags: string[];
	};
	shortid: string;
	settings?: {
		diffuse: {
			texture: {
				value: string;
			};
		};
		repetitionFactor: {
			x: number;
			y: number;
		};
	};
	featured?: boolean;
	isVerified?: boolean;
	tokens: any[];
}

export interface Light {
	_id: string;
	id: string;
	isPublic: boolean;
	meta: {
		name: string;
		description: string;
		category: typeof LightTagCategory[];
		teaser?: string;
		thumbnail: string;
		tags?: string[];

		isBackup?: boolean;
		nEdits?: number;
		nViews?: number;
		tsCreated?: string;
		tsModified?: string;
	};
	shortid: string;
	settings: {
		type: string;
		intensity?: number;
		lightgroup?: string;
		sourceangle?: number;
		sourceradius?: number;
		sourcewidth?: number;
		sourceheight?: number;
		length?: number;
		temperature?: number;
		attenuation?: number;
		outerangle?: number;
		innerangle?: number;
		castshadow?: boolean;
		ies?: {
			active: boolean;
			iesid: string;
			iesmultiplieractive: boolean;
			iesmultiplier: number;
		};
		color?: {
			r: number;
			g: number;
			b: number;
		};
	};
	object?: {
		src: string;
		positionOffset: any;
		rotationOffset: any;
		scaleOffset: any;
	},
	featured?: boolean;
	isVerified?: boolean;
	tokens: any[];
}

export interface LibraryContext {
	objects: SceneObject[];
	objectsQuery: PageQuery;
	pagedObjects: PagedResponse<SceneObject> | undefined;
	objectsCount: number;
	currentObject: SceneObject | undefined;
	loadingObjects: boolean;
	errorObjects?: any;
	setCurrentObject: (idOrNull?: string | null) => void;
	setObjectsQuery: (query: PageQuery) => void;
	loadMoreObjects: () => void;
	resetCurrentObject: () => void;
	allObjects: SceneObject[];
	fetchAllObjects: () => void;

	materials: Material[];
	materialsQuery: PageQuery;
	pagedMaterials: PagedResponse<Material> | undefined;
	currentMaterial: Material | undefined;
	loadingMaterials: boolean;
	errorMaterials?: any;
	setCurrentMaterial: (idOrNull?: string | null) => void;
	setMaterialsQuery: (query: PageQuery) => void;
	loadMoreMaterials: () => void;
	allMaterials: Material[];
	fetchAllMaterials: () => void;

	lights: Light[];
	lightsQuery: PageQuery;
	pagedLights: PagedResponse<Light> | undefined;
	currentLight: Light | undefined;
	loadingLights: boolean;
	errorLights?: any;
	setCurrentLight: (idOrNull?: string | null) => void;
	setLightsQuery: (query: PageQuery) => void;
	loadMoreLights: () => void;
	allLights: Light[];
	fetchAllLights: () => void;

}
