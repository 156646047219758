import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
} from "react";
import useFetch from "use-http";
import { useLocation } from "react-router-dom";
import {
	ArcwareInit,
	ArcwarePixelStreaming,
	ArcwareApplication,
} from "@arcware-cloud/pixelstreaming-websdk";
import { ACCESS_TOKEN_COOKIE } from "common/constants/cookies";
import { useCookies } from "react-cookie";
import PageQuery, { getQueryStringFromPageQuery } from "common/types/PageQuery";
import useProjects from "contexts/Projects/useProjects";
import { useOrganizations } from "contexts/Organizations";
import { SCENES } from "common/routes.constants";
import { PagedResponse } from "common/types/PagedResponse";
import isSceneProcessing from "utils/isSceneProcessing";
import { WEBSOCKET_EVENT_CODE_NO_STATUS } from "views/SharedScene/StreamingStatesConstants";
import { deepMerge } from "utils/mergeObjects";
import {
	Scene,
	SceneExtended,
	BimcollabTokenData,
	LodType,
	Cesium,
	sceneDetailProps,
} from "./types";
import Context from "./context";

const SCENE_POOLING_INTERVAL =
	Number(process.env.REACT_APP_SCENE_POOLING_INTERVAL) || 20000;

const ScenesProvider: React.FC = ({ children }) => {
	const { pathname } = useLocation();
	const { current: currentProject } = useProjects();
	const { current: currentOrganization, user } = useOrganizations();
	const [current, setCurrent] = useState<SceneExtended | undefined>();

	const [lodValue, setLodValue] = useState<LodType>(0);
	const [editorState, setEditorState] = useState<string | null>(null);

	const [cookies] = useCookies([ACCESS_TOKEN_COOKIE]);

	const [scenes, setScenes] = useState<Scene[]>([]);
	const [BIMIssues, setBIMIssues] = useState<any>([]);
	const [viewpoints, setViewpoints] = useState<any | null>(null);
	const [viewpointBeingAdded, setViewpointBeingAdded] = useState<any | null>(
		null,
	);
	const [objectReplaceMode, setObjectReplaceMode] = useState<boolean>(false);
	const [refreshTopics, setRefreshTopics] = useState<boolean>(false);
	const [scenesInProcess, setScenesInProcess] = useState<Scene[]>([]);
	const [
		currentBimcollab,
		setCurrentBimcollab,
	] = useState<BimcollabTokenData>();

	const [query, setQuery] = useState<PageQuery>({
		limit: 48,
		page: 1,
		search: {
			text: "",
			fields: [],
		},
		sort: "-meta.tsModified",
	});
	const [pagedResponse, setPagedResponse] = useState<PagedResponse<Scene>>();
	const append = useRef<boolean>(false);

	// PIXEL STREAMING STATES
	const [state, setState] = useState<string | null>("welcome");
	const [orgProjScene, setOrgProjScene] = useState<string | null>(null);
	const [sceneLoaded, setSceneLoaded] = useState<boolean>(false);
	const [streamingResponse, setStreamingResponse] = useState<any | null>(
		null,
	);

	const [
		arcwareApplication,
		setArcwareApplication,
	] = useState<ArcwareApplication | null>(null);
	const [
		pixelStreaming,
		setPixelStreaming,
	] = useState<ArcwarePixelStreaming | null>(null);
	const [applicationResponse, setApplicationResponse] = useState("");
	const [
		streamingCloseEvent,
		setStreamingCloseEvent,
	] = useState<CloseEvent | null>(null);

	const isStage =
		!(process.env.NODE_ENV === "production") ||
		window?.location?.hostname?.includes("hegias-stage.com");

	// PIXEL STREAMING FUNCTIONS AND HANDLERS
	const closeWebRTCClient = () => {
		console.log(
			"Closing [streaming] client... Socketstates before closing: ",
			pixelStreaming?.WebsocketStates,
		);
		if (arcwareApplication || pixelStreaming) {
			(arcwareApplication as any).stream?.disconnect();
			(pixelStreaming as any)?.disconnect();
			setArcwareApplication(null);
			setStreamingCloseEvent(null);
			setPixelStreaming(null);
			console.log(
				"Closed [streaming] client. Remaining active instances:",
				pixelStreaming?.ActiveInstances !== undefined
					? pixelStreaming.ActiveInstances
					: "unknown",
			);
		} else {
			console.warn("No [streaming] client to close.");
		}
	};

	const sendMessage = (message: any) => {
		console.log("Sent message to [streaming]:", message);
		(arcwareApplication as any)?.emitUIInteraction(message);
	};

	const setFlag = (flag: string, value: boolean) => {
		if (pixelStreaming) {
			// console.log("[streaming] setting flag:", flag, value, pixelStreaming.config?.flags?.get(flag));
			pixelStreaming.config?.flags?.set(flag, value);
		}
	};

	useEffect(() => {
		console.log("[streaming] response:", applicationResponse);

		if (!applicationResponse) {
			return;
		}

		const json = JSON.parse(applicationResponse);

		if (json?.scene?.loadingstatus === "done") {
			setSceneLoaded(true);
			setState("sceneLoaded");
		}

		if (json.viewpoint) {
			console.log("viewpoint from navigator:", json.viewpoint);
		}
	}, [applicationResponse]);

	const [rootScene, setRootScene] = useState<SceneExtended | null>(null);

	useEffect(() => {
		if (current) {
			setRootScene(current);
		}
	}, [current]);

	const saveScene = useCallback(
		async (scene: SceneExtended, saveAs = false) => {
			if (!current) {
				return;
			}
			const saveSceneUrl = saveAs
				? `/projects/${currentProject?._id}/scenes/${current._id}/save-as`
				: `/projects/${currentProject?._id}/scenes/${current._id}`;

			if (current && currentProject) {
				await put(saveSceneUrl, scene);
			}
		},
		[current, currentProject],
	);

	const saveEditorScene = useCallback(
		async (sceneEditor: Partial<SceneExtended>, saveAs = false) => {
			if (rootScene && sceneEditor) {
				// Delete params related to location
				const sceneEditorCopy = { ...sceneEditor };
				const sceneUpdated = await getScene(
					`/${rootScene._id as string}`,
				);
				if (sceneUpdated) {
					if (sceneEditorCopy.scene?.params?.cesium) {
						delete sceneEditorCopy.scene?.params?.cesium;
					}
					if (sceneEditorCopy.meta) {
						delete sceneEditorCopy.meta;
					}
					delete sceneEditorCopy.scene?.params?.isnavwhitemodel;

					const updatedScene = (deepMerge(
						(sceneUpdated as unknown) as Record<string, unknown>,
						sceneEditorCopy,
					) as unknown) as SceneExtended;

					await saveScene(updatedScene, saveAs);
					setRootScene(updatedScene as SceneExtended);
				}
			}
		},
		[rootScene, saveScene],
	);

	const saveLocationScene = useCallback(
		async (sceneLocation: Cesium) => {
			if (rootScene && sceneLocation) {
				const sceneLocationCopy = {
					scene: {
						params: {
							cesium: sceneLocation,
						},
					},
				};
				const updatedScene = (deepMerge(
					(rootScene as unknown) as Record<string, unknown>,
					sceneLocationCopy,
				) as unknown) as SceneExtended;

				await saveScene(updatedScene);
				setRootScene(updatedScene);
			}
		},
		[rootScene, saveScene],
	);

	const saveDetailScene = useCallback(
		async (sceneDetail: sceneDetailProps) => {
			if (rootScene && sceneDetail) {
				const updatedScene = (deepMerge(
					(rootScene as unknown) as Record<string, unknown>,
					sceneDetail,
				) as unknown) as SceneExtended;
				await saveScene(updatedScene);
				setRootScene(updatedScene);
			}
		},
		[rootScene, saveScene],
	);

	const savePartialSceneInformation = useCallback(
		async (scenePartialInformation: Partial<SceneExtended>) => {
			if (rootScene && scenePartialInformation) {
				const updatedScene = (deepMerge(
					(rootScene as unknown) as Record<string, unknown>,
					scenePartialInformation,
				) as unknown) as SceneExtended;
				await saveScene(updatedScene);
				setRootScene(updatedScene);
			}
		},
		[rootScene, saveScene],
	);

	useEffect(() => {
		// Check if the user has switched scene, project or organization in the CMS frontend
		if (
			orgProjScene &&
			orgProjScene !==
				`${currentOrganization?._id}-${currentProject?._id}-${current?._id}`
		) {
			closeWebRTCClient();
			setState("welcome");
			setOrgProjScene(null);
			setSceneLoaded(false);
		}

		if (state === "readyToInit") {
			if (arcwareApplication) {
				console.warn(
					"[streaming] application already exists, skipping initialization...",
				);
				return;
			}

			const { Config, PixelStreaming, Application } = ArcwareInit(
				{
					shareId: isStage
						? "share-25ffa203-3ea3-4463-93b8-590d4d751c26"
						: "share-0c794eb2-7a9f-4df8-b5f6-8196e73f5ca1",
				},
				{
					initialSettings: {
						StartVideoMuted: true,
						AutoConnect: true,
						AutoPlayVideo: true,
						// // Input
						// KeyboardInput: boolean; // Default: true,
						// MouseInput: boolean; // Default: true,
						// GamepadInput: boolean; // Default: false,
						// TouchInput: boolean; // Default: true,
						// XRControllerInput: boolean; // Default: false,
						// UseMic:  boolean; // Default: true,
					},
					settings: {
						infoButton: false,
						micButton: false,
						audioButton: false,
						fullscreenButton: false,
						settingsButton: false,
						connectionStrengthIcon: false,
						// stopButton?: boolean; //Default FALSE

						// import type { ErrorMessage, Queue, LoveLetter } from "@arcware-cloud/pixelstreaming-websdk";
						// Handler for server side error messages.
						// errorHandler?: (message: ErrorMessage) => void;
						// Handler for queue events.
						// queueHandler?: (message: Queue) => void;
						// Handler for sessionId message.
						// sessionIdHandler?: (sessionId: string) => void;
						// Handler for love letters. "LoveLetters" are send from backend to the SDK to state what phase the connection currently is in.
						// loveLetterHandler?: (message: LoveLetter) => void;
						// Enable/Disable LoveLetter logging to the console.
						loveLetterLogging: false,
						// Enable/Disable Connection Identifier logging to the console.
						connectionIdentifierLoggingDisabled: true,
					},
				},
			);

			Application.getApplicationResponse((response) =>
				setApplicationResponse(response),
			);

			PixelStreaming.videoInitializedHandler.add(() => {
				// console.log("[streaming] video initialized.");
				setState("videoInitialized");
			});

			PixelStreaming.queueHandler.add((message) =>
				console.log("[streaming] queueHandler message:", message),
			);

			// Assuming you have your PixelStreaming of ArcwareInit at hand ...
			PixelStreaming.websocketOnCloseHandler.add((event: CloseEvent) => {
				if (event.code !== WEBSOCKET_EVENT_CODE_NO_STATUS) {
					// Ignore event 1005 (no status code was present), for all others go into error state
					setStreamingCloseEvent(event);
				}
			});

			// console.log('[streaming] Application', Application);

			setArcwareApplication(Application);
			setPixelStreaming(PixelStreaming);
			setStreamingCloseEvent(null);

			// console.log(PixelStreaming.config.flags.get("KeyboardInput"));
			// console.log(PixelStreaming.config.flags.set("KeyboardInput", false));

			// console.log('[streaming] PixelStreaming', PixelStreaming);

			// console.log('[streaming] Config', Config);

			Application.getApplicationResponse((response) =>
				setApplicationResponse(response),
			);

			setState("initializingStreaming");
			setSceneLoaded(false);
		} else if (state === "videoInitialized") {
			if (
				!currentOrganization?._id ||
				!currentProject?._id ||
				!current?._id ||
				!cookies[ACCESS_TOKEN_COOKIE]
			) {
				// console.log("[streaming] Still missing organization, project or scene data, or bearer token from cookie.", currentOrganization, currentProject, current, cookies[ACCESS_TOKEN_COOKIE]);
				return;
			}
			sendMessage({
				app: {
					shareid: "",
					sceneid: current?._id,
					projectid: currentProject?._id,
					organizationid: currentOrganization?._id,
					isstage: isStage,
					forceupdate: "false",
					bearer: cookies[ACCESS_TOKEN_COOKIE],
				},
			});
			setState("loadingScene");
			setSceneLoaded(false);
		} else if (state === "sceneLoaded") {
			// console.log("[streaming] scene loaded.");
			setOrgProjScene(
				`${currentOrganization?._id}-${currentProject?._id}-${current?._id}`,
			);
		}
	}, [state, currentOrganization, currentProject, current, isStage, cookies]);

	const { get: getScenes, loading, error } = useFetch();

	const {
		get: getScene,
		loading: currentLoading,
		error: currentSceneError,
	} = useFetch(`/projects/${currentProject?._id}/scenes`);

	const { post: createShareToken } = useFetch(
		`/projects/${currentProject?._id}/scenes/${current?._id}/token`,
	);

	const { get: getSceneStatus } = useFetch(
		`/projects/${currentProject?._id}/scenes`,
	);

	const { put, response: putResponse } = useFetch();

	const setCurrentScene = async (shortid?: string) => {
		const match = scenes?.find((s) => s.shortid === shortid);
		logger.debug("[ScenesProvider] setCurrentScene", { shortid, match });

		// can not fetch random scene ID, has to come from the current array of scenes
		if (match) {
			const scene = await getScene(`/${match._id as string}`);
			if (scene) {
				setLodValue(scene.settings.lod ?? 0);
				setCorrectLodScene(scene, scene.settings.lod);
			}
		} else {
			setCurrent(undefined);
			setViewpoints(null);
		}
	};

	const getSceneShortid = (_pathname: string): string => {
		const chunks = _pathname.split("/");
		const lastTwo = chunks.slice(-2);

		try {
			const [entity, shortid] = lastTwo;

			if (entity === SCENES && !!shortid) {
				return shortid;
			}

			return "";
		} catch {
			return "";
		}
	};

	const memoizedGetSceneShortId = useCallback(
		(_pathname) => getSceneShortid(_pathname),
		[],
	);

	const fetchScenes = async () => {
		const queryString = getQueryStringFromPageQuery(query);
		const response = await getScenes(
			`/projects/${currentProject?._id}/scenes?${queryString}`,
		);

		const results = append.current
			? [...scenes, ...response.results]
			: response.results;

		setScenes(results);
		setPagedResponse(response);
		append.current = false;
	};

	const loadMore = () => {
		if (!pagedResponse?.hasNextPage) {
			return;
		}

		append.current = true;
		setQuery((previousQuery) => ({
			...previousQuery,
			page: previousQuery.page + 1,
		}));
	};

	const fetchSceneStatus = async (id: string) => {
		const response = await getSceneStatus(`/${id}/status`);
		return response.status;
	};

	const encodeData = (data: string): string => {
		const encodedData = btoa(data);
		return encodedData;
	};

	const sendTrackingMessage = async (eventType: string, message: any) => {
		if (!eventType) {
			return;
		}
		const ts = Math.floor(new Date().getTime() / 1000);
		const trackingMessage = {
			app_id: "HEGIAS.creator",
			send_ts: ts,
			events: [
				{
					user_id: user._id,
					scene_id: current?._id,
					org_id: currentOrganization?._id,
					comment: message.comment || "",
					event_message: JSON.stringify(message),
					event_start_ts: ts,
					event_end_ts: ts,
					event_type: eventType,
				},
			],
			// app_version: "",
			// organizationID: currentOrganization?._id,
			// projectID: currentProject?._id,
			// sceneID: current?._id,
			// isStage,
		};

		const response = await fetch(
			isStage
				? `https://ihjyd7jnv3ojmd4kbf2ebap2oa0nmeuk.lambda-url.eu-west-1.on.aws`
				: `https://hlbsq5djemymf5savmkx3vtjzu0biyqo.lambda-url.eu-west-1.on.aws/`,
			{
				method: "POST",
				mode: "no-cors",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies[ACCESS_TOKEN_COOKIE]}`,
					"Access-Control-Allow-Origin": "*",
				},
				body: encodeData(JSON.stringify(trackingMessage)),
			},
		);

		// const data = await response.json();
		// return data;
	};

	const fetchViewpoints = async () => {
		try {
			// console.log("Fetching viewpoints");
			const response = await fetch(
				// '/projects/:id/scene/:sceneID/poi'
				`${process.env.REACT_APP_BASE_URL}/projects/${currentProject?._id}/scene/${current?._id}/poi`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${cookies[ACCESS_TOKEN_COOKIE]}`,
					},
				},
			);

			const data = await response.json();
			setViewpoints(data);
			return data;
		} catch (err) {
			console.error("fetchViewpoints ERROR-->", err);
		} finally {
			// setLoadingViewpoints(false);
		}
	};

	const updateViewpoint = async (viewpoint: any) => {
		try {
			const response = await fetch(
				// '/projects/:id/scene/:sceneID/poi'
				`${process.env.REACT_APP_BASE_URL}/projects/${currentProject?._id}/poi/${viewpoint._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${cookies[ACCESS_TOKEN_COOKIE]}`,
					},
					body: JSON.stringify(viewpoint),
				},
			);

			const data = await response.json();
			return data;
		} catch (err) {
			console.error("updateViewpoint ERROR-->", err);
		} finally {
			// nothing to do here
		}
	};

	const updateViewpointOrder = async (poiOrderPairs: any[]) => {
		try {
			const response = await fetch(
				// '/projects/:id/scene/:sceneID/poi'
				`${process.env.REACT_APP_BASE_URL}/projects/${currentProject?._id}/poi`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${cookies[ACCESS_TOKEN_COOKIE]}`,
					},
					body: JSON.stringify(poiOrderPairs),
				},
			);

			const data = await response.json();
			return data;
		} catch (err) {
			console.error("updateViewpointsOrder ERROR-->", err);
		} finally {
			// nothing to do here
		}
	};

	const getSceneOffset = () => {
		if (current) {
			const scene = current as any;
			// Determine if the scene is centered
			const isCentered =
				scene.pipelineOptions?.centerFeature?.isCentered === true ||
				scene.pipelineOptions?.centerFeature?.isCentered === "true";

			// Determine if the positions in the scene have been offsetted
			// const positionsAreOffsetted = scene.scene.children?.[0]?.params?.isOffsetted === true || scene.scene.children?.[0]?.params?.isOffsetted === "true";

			// Find the last of the reports in the scene to get the correct position offset
			if (isCentered && scene?.report?.length > 0) {
				// for (let index = scene.report.length - 1; index >= 0; index -= 1) {
				for (let index = 0; index < scene.report.length; index += 1) {
					const element = scene.report[index];
					if (
						element.translationVector?.x !==
						undefined /* && element.scenePosition?.x */
					) {
						return {
							x: element.translationVector.x, // + element.scenePosition.x,
							y: element.translationVector.y, // + element.scenePosition.y,
							z: element.translationVector.z, // + element.scenePosition.z
						};
					}
				}
			}
		}

		return { x: 0, y: 0, z: 0 };
	};

	const goToViewpoint = (object: any, animated = true) => {
		const bcfSystem = (document.querySelector("#bcf-system") as any)
			?.components["bcf-system"];
		if (bcfSystem) {
			/*
			TODO: coordinates have to be transformed to orbitcontrol coordinates before switching to modelview mode here
			// Setting correct view (model view vs walkmode)
			const editorScene = document.querySelector("#scene");
			if (editorScene) {
				if (object.Scale === 10) {
					(editorScene as any).emit("enter-modelview", "3D");
				} else if (object.Scale === 1) {
					(editorScene as any).emit("exit-modelview", "3D");
				} else {
					console.warn("Viewpoint scale not supported in editor. Scale:", object.Scale);
					return; // viewpoint scale not supported in editor
				}
			}
			*/

			const offsetPos = { ...getSceneOffset() };

			console.log("Navigating to viewpoint", object);

			let vpEulerNull = true;
			if (object.Euler) {
				if (
					(object.Euler.x || 0) +
						(object.Euler.y || 0) +
						(object.Euler.z || 0) !==
					0
				) {
					vpEulerNull = false;
				}
			}

			if (object.Scale === 1) {
				if (vpEulerNull === false) {
					// console.log("Euler angles provided", object.Euler);
					bcfSystem.teleportToIssue(
						{
							x: object.Position.x,
							y: object.Position.z,
							z: object.Position.y,
						},
						{ x: 0, y: 0, z: 0 }, // takes a 2D directionVector, will be ignored here
						"3D",
						{
							x: (object.Euler.x * Math.PI) / 180.0,
							y: (object.Euler.y * Math.PI) / 180.0,
							z: 0,
						}, // if this is provided, teleport will ignore direction vector above
						false, // animated
						false, // focusing
					);
				} else if (object.Rotation?.x !== undefined) {
					// console.log("Direction vector provided", object.Rotation);
					bcfSystem.teleportToIssue(
						{
							x: object.Position.x,
							y: object.Position.z,
							z: object.Position.y,
						},
						{
							x: object.Rotation.x,
							y: object.Rotation.z,
							z: object.Rotation.y,
						}, // Warning: teleportToIssue() only takes a 2D directionVector (x, y)
						"3D",
						undefined,
						false, // animated
						false, // focusing
					);
				}
			} else if (object.Scale > 1) {
				const mvPosition = {
					x: offsetPos.x + object.Position.x,
					z: -offsetPos.y + object.Position.y,
					y: -(offsetPos.z - object.Position.z),
				};
				console.log(
					"Navigating to modelview viewpoint",
					mvPosition,
					offsetPos,
				);
				console.log(
					bcfSystem.applyMvViewpoint({
						mvPosition,
						mvPivot: object.Pivot
							? {
									x: offsetPos.x + object.Pivot.x,
									z: -offsetPos.y + object.Pivot.y,
									y: -(offsetPos.z - object.Pivot.z),
							  }
							: { x: 0, y: 0, z: 0 }, // support for legacy modelview viewpoints from VR app (that don't have pivot)
					}),
				);
			}
		}
	};

	useEffect(() => {
		if (editorState === "loadingScene" && !viewpoints) {
			fetchViewpoints();
		}
		if (editorState === "done" && viewpoints?.length > 0) {
			// goToViewpoint(viewpoints[0], false);				DO NOT go to first viewpoint in Editor automatically, change of thought
		}
	}, [editorState, viewpoints]);

	const updateScene = async () => {
		if (!current) {
			return;
		}

		let response;
		if (current && currentProject) {
			response = await put(
				`/projects/${currentProject._id}/scenes/${current._id}`,
				current,
			).then((data) => {
				return data;
			});
		}
	};

	const addViewpoint = async (viewpoint: any) => {
		if (!currentProject || !current) {
			console.error(
				"Current scene or project not set - can't add viewpoint.",
				viewpoint,
			);
			return;
		}
		try {
			const newViewpoint = {
				...viewpoint,
				SceneID: current._id,
				ProjectID: currentProject._id,
				isActive: true,
				Order: viewpoints?.length || 1,
			};
			// console.log("Adding new viewpoint", newViewpoint);

			// Save in backend
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/projects/${currentProject?._id}/poi`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${cookies[ACCESS_TOKEN_COOKIE]}`,
					},
					body: JSON.stringify(newViewpoint),
				},
			);

			const data = await response.json();
			// console.log("New viewpoint saved:", data);

			/* TODO: update viewpoint in DB with new name
			if (data.meta) {
				data.meta.name = `Viewpoint ${data.CreationNumber}`;
			}
			// todo: update viewpoint in DB with new name
			*/

			// add to list in CMS and allow adding another viewpoint only after half a second
			setTimeout(() => {
				setViewpoints([...viewpoints, data]);
				setViewpointBeingAdded(null);
			}, 100);
			return data;
		} catch (err) {
			console.error("addViewpoint ERROR-->", err);
		}
	};

	const deleteViewpoint = async (viewpoint: any) => {
		if (!currentProject || !current) {
			console.error(
				"Current scene or project not set - can't delete viewpoint.",
				viewpoint,
			);
			return;
		}
		try {
			console.log("Deleting viewpoint", viewpoint);

			// Save in backend
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/projects/${currentProject?._id}/poi/${viewpoint._id}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${cookies[ACCESS_TOKEN_COOKIE]}`,
					},
				},
			);

			const data = await response.json();
			// console.log("Viewpoint deleted:", data);

			// remove from list in CMS
			const newViewpoints = viewpoints.filter(
				(vp: any) => vp._id !== viewpoint._id,
			);
			setViewpoints(newViewpoints);
			return data;
		} catch (err) {
			console.error("deleteViewpoint ERROR-->", err);
		}
	};

	const createShareLink = async (tokenInfo: any) => {
		let result = [];

		result = await createShareToken({
			token: tokenInfo,
		});

		return result;
	};

	useEffect(() => {
		if (currentProject?._id) {
			setScenes([]);
			fetchScenes();
		} else {
			setScenes([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProject?._id, query]);

	useEffect(() => {
		if (!currentProject || !scenes?.length) {
			setCurrentScene("");
			return;
		}

		const sceneShortId = memoizedGetSceneShortId(pathname);
		if (current?._id !== sceneShortId) {
			setCurrent(undefined);
			setViewpoints(null);
		}
		logger.debug("[ScenesProvider] sceneShortId", {
			pathname,
			sceneShortId,
			currentProject,
			scenes,
		});
		setCurrentScene(sceneShortId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, currentProject, scenes]);

	// Extract & save scenes in process
	useEffect(() => {
		const processing = scenes?.filter(isSceneProcessing);
		if (processing) {
			setScenesInProcess(processing);
		}
	}, [scenes]);

	// Handle pooling for scenes in process
	useEffect(() => {
		const tick = async () => {
			const updatedScenesInProcess: Scene[] = await Promise.all(
				scenesInProcess.map(async (scene: Scene) => ({
					...scene,
					pipelineStatusSummary: await fetchSceneStatus(scene._id),
				})),
			);

			// Compare all objects in all processing scenes if they have changed the status
			const scenesMatch = updatedScenesInProcess.every((bScene) => {
				const aScene = scenes.find(
					(s) => s._id === bScene._id,
				) as Scene;
				const match =
					aScene.pipelineStatusSummary ===
					bScene.pipelineStatusSummary;

				return match;
			});

			logger.debug("[ScenesProvider] tick", {
				scenes,
				scenesInProcess,
				updatedScenesInProcess,
				scenesMatch,
			});

			// replace all scenes with the match from `scenesInProcess` because of status change
			if (!scenesMatch) {
				const updatedScenes = scenes.map((scene) => {
					const updateCandidate = updatedScenesInProcess.find(
						(updatedScene) => updatedScene._id === scene._id,
					);
					if (updateCandidate) {
						return {
							...scene,
							pipelineStatusSummary:
								updateCandidate.pipelineStatusSummary,
						};
					}

					return scene;
				});

				logger.info("[ScenesProvider] updating scenes list", {
					updatedScenes,
				});

				setScenes(updatedScenes);

				fetchScenes();
			}
		};

		const id = setInterval(tick, SCENE_POOLING_INTERVAL);

		return () => {
			clearInterval(id);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scenesInProcess]);

	/* LOD settings */

	const availableLods = useMemo(() => {
		// At scene load or switch: reset streaming close event, if any
		setStreamingCloseEvent(null);

		if (!current) {
			return 0;
		}
		if (!current.scene.children) {
			return 0;
		}
		let max = 0;

		current.scene.children.forEach((child: any) => {
			const numberOfLod = child.lod?.length ?? 0;
			if (numberOfLod > max) {
				max = numberOfLod;
			}
		});

		return max;
	}, [current]);

	const setCorrectLodScene = (scene: SceneExtended, lod?: number) => {
		const currentLod = lod ?? lodValue;

		if (!scene.scene.children) {
			setCurrent(scene);
			setViewpoints(null);
		}
		const newSceneJSON = { ...scene };

		newSceneJSON.scene.children = newSceneJSON.scene.children.map(
			(child: any) => {
				const newChild = { ...child };
				const newLod = child.lod?.[currentLod];

				if (newLod) {
					newChild.params.src = newLod.src;
				}
				return newChild;
			},
		);
		setCurrent(newSceneJSON);
		setViewpoints(null);
	};

	useEffect(() => {
		if (current) {
			setCorrectLodScene(current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lodValue]);

	/* Checks for errors */

	if (error) {
		logger.debug("[ScenesProvider] Critical error w/scenes", error);
		return <p>Error: {JSON.stringify(error)}</p>;
	}

	if (currentSceneError) {
		logger.debug(
			"[ScenesProvider] Critical error w/ current",
			currentSceneError,
		);
		return <p>Error: {JSON.stringify(currentSceneError)}</p>;
	}

	return (
		<Context.Provider
			value={{
				fetchScenes,
				scenes,
				current,
				setCurrent,
				saveLocationScene,
				saveEditorScene,
				saveDetailScene,
				savePartialSceneInformation,
				currentLoading,
				loading,
				query,
				updateScene,
				pagedResponse,
				currentBimcollab,
				lodValue,
				setLodValue,
				setCurrentScene,
				availableLods,
				setQuery,
				loadMore,
				createShareLink,
				setCurrentBimcollab: (data: any) => setCurrentBimcollab(data),
				BIMIssues,
				setBIMIssues: (issues: any) => setBIMIssues(issues),
				refreshTopics,
				setRefreshTopics: (value: boolean) => setRefreshTopics(value),

				sendTrackingMessage,

				viewpoints,
				fetchViewpoints,
				setViewpoints,
				goToViewpoint,
				addViewpoint,
				deleteViewpoint,
				updateViewpoint,
				updateViewpointOrder,
				viewpointBeingAdded,
				setViewpointBeingAdded,
				editorState,
				setEditorState,

				streamingState: state,
				setStreamingState: setState,
				orgProjScene,
				setOrgProjScene,
				sceneLoaded,
				setSceneLoaded,
				streamingResponse,
				setStreamingResponse,
				arcwareApplication,
				setArcwareApplication,
				pixelStreaming,
				setPixelStreaming,
				applicationResponse,
				setApplicationResponse,
				sendMessage,
				setFlag,
				closeStreaming: closeWebRTCClient,
				streamingCloseEvent,
				setStreamingCloseEvent,
				objectReplaceMode,
				setObjectReplaceMode,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default ScenesProvider;
